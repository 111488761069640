import { Grid } from "@mui/material";
import { useUserContext } from "@fenix/core/context";
import { useEffect } from "react";
import { getModules } from "./dashboard.api";
import React, { useState } from "react";
import { Tile } from "./Tile";

export const Dashboard = () => {
  const { userContext, token } = useUserContext();
  const [modules, setModules] = useState(null);
  useEffect(() => {
    (async () => {
      console.log(userContext);
      console.log(token);
      let ret = await getModules();
      console.log(ret);
      if (ret.error === undefined) setModules(ret);
    })();
  }, []);

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
        id={`modules-grid`}
      >
        {modules &&
          modules?.map((module, i) => (
            <Tile
              photo={module.image}
              name={module.name}
              description={module.description}
              url={module.url}
              index={i}
              key={i}
            />
          ))}
      </Grid>
    </>
  );
};

export default Dashboard;
