import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    marginBottom: theme.spacing(2) + " !important",
  },
  table: {
    minWidth: 700,
  },
  tableHead: {
    fontWeight: 700,
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  hidden: {
    display: "none !important",
  },
  alertMessage: {
    marginTop: theme.spacing(1),
    fontStyle: "italic",
  },
  loginForm: {
    backgroundColor: "#ffffff",
    borderRadius: 15,
    width: 640,
    maxWidth: "100%",
    marginTop: 10,
    padding: "30px 20px 25px 20px",
    "& button": {
      borderRadius: 8,
      height: 60,
      fontSize: 22,
      textTransform: "none",
      marginTop: 20,
      marginBottom: 13,
    },
    "& .link": {
      textAlign: "center",
      "& a": {
        fontSize: 14,
        color: theme.palette.common.black,
        textDecorationColor: theme.palette.common.black,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  checkbox: {
    marginTop: -15,
    "& .check": {
      color: "#707070",
    },
  },
  input: {
    marginBottom: 2,
  },
}));

export default useStyles;
