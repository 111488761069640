import { Container, Typography } from "@mui/material";
import React from "react";
import useStyles from "./style";

const NotFound = () => {
  const classes = useStyles();

  return (
    <Container className={classes.paper} component="main" maxWidth="md">      
      <Typography className={classes.title} component="h1" variant="h5">
        Nie znaleziono strony o podanym adresie.
      </Typography>
    </Container>
  );
};

export default NotFound;
