import App from "./application/App";
import { Application } from "@fenix/core/application/Application";

import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Application>
    <App />
  </Application>
);
