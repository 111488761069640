import { Grid } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";

export const FormWrapper = ({ props, xs, sm, md, render, checkbox = false, defaultNull = false, id }) => {
  const { control, resetField, setValue } = props.form;
  const val = props.value;
  const value = useMemo(() => val, []);
  const name = props.name;
  const defaultValue = useMemo(() => (value || value === 0 ? value : checkbox ? false : defaultNull ? null : ""), [checkbox, defaultNull, value]);

  useEffect(() => {
    if (defaultValue || defaultValue === 0) {
      resetField(name, { defaultValue });
    }
  }, [defaultValue, resetField, name]);

  return (
    <Grid item xs={xs ?? 12} sm={sm ?? 12} md={md} sx={{ position: "relative" }} id={id}>
      <Controller
        name={name}
        control={control}
        required={props.required}
        defaultValue={defaultValue}
        setValue={setValue}
        rules={props.rules}
        render={render}
      />
    </Grid>
  );
};

export default FormWrapper;
