import Helmet from "react-helmet";

const Meta = meta => {
  return (
    <Helmet>
      <title>Karta mieszkańca - {meta.subTitle || ""}</title>
    </Helmet>
  );
};

export default Meta;