import { IconButton } from "@mui/material";
import { Close as CloseIcon }from "@mui/icons-material";

import { SnackbarProvider, closeSnackbar } from "notistack";

const APIErrorSnackBarProvider = (props) => 
    <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        style={{ width: "100%", fontSize: "18px", fontFamily: "Poppins", paddingRight: "40px" }}
        action={snackbarId => (
        <IconButton
            id="index-snackbar-close-btn"
            style={{ position: "absolute", top: "6px", right: 0 }}
            onClick={() => closeSnackbar(snackbarId)}
        >
            <CloseIcon />
        </IconButton>
        )}
        classes={{ root: { background: "red" } }}
    >
        {props.children}
    </SnackbarProvider>


export default APIErrorSnackBarProvider;