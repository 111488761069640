import React from "react";

import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { LicenseInfo } from "@mui/x-license-pro";

import { Layout } from "./Layout";

import APIErrorSnackBarProvider from "../components/APIErrorNotification/APIErrorSnackBarProvider";
import APIErrorNotification from "../components/APIErrorNotification/APIErrorNotification";
import APIErrorProvider from "../components/APIErrorNotification/APIErrorProvider";
import { UserContextProvider } from "@fenix/core/context";

import * as theme from "../themes/index";
import ConditionalStrictMode from "./ConditionalStrictMode";

export const Application = (props) => {
  LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_DATAGRID_LICENSE_KEY);

  return (
    <ConditionalStrictMode disable={props.disableStrictMode}>
      <UserContextProvider withSession userContext={props.userContext}>
        <APIErrorProvider>
          <BrowserRouter>
            <APIErrorSnackBarProvider>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme.light}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Layout showMenu={props.showMenu} navigation={props.navigation}>
                      {props.children}
                    </Layout>
                    <APIErrorNotification />
                  </LocalizationProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </APIErrorSnackBarProvider>
          </BrowserRouter>
        </APIErrorProvider>
      </UserContextProvider>
    </ConditionalStrictMode>
  );
};
