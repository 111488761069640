import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { useState } from "react";
export const Tile = ({
  name,
  description,
  photo,
  url,
  index = 0,
  disabled,
  children,
  childrenSize = 12,
  spacing = 1,
}) => {
  const [redirectUrl] = useState(`${url}`);
  
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        my: 3,
      }}
      id={"tile-" + index}
    >
      <ButtonBase  href={redirectUrl}>
        <Box
          sx={{ width: { xs: "100%", lg: 300 }, height: { xs: 250, lg: 170 } }}
        >
          <CardMedia
            sx={{
              width: { xs: "100%", lg: 300 },
              height: { xs: 250, lg: "10.5em" },
              backgroundSize: "fill",
              objectFit: "scale-down",
              display: "flex",
              alignItems: "center",
              filter: disabled ? "grayscale(100%)" : null,
            }}
            component="img"
            image={photo ? photo : ""}
            alt={`${name}`}
          />
        </Box>
        <CardContent
          sx={{
            flex: 1,
            marginLeft: "10px",
            width: { xs: "100%", lg: 300 },
            height: { xs: 250, lg: 170 },
          }}
        >
          <Grid container spacing={spacing} direction="row">
            <Grid item>
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxHeight: "130px",
                }}
                id={"tile-name-" + index}
                variant="h5"
                component="div"
                color={disabled ? "text.disabled" : "primary"}
              >
                {name}
              </Typography>
              <br />
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxHeight: "100px",
                }}
                id={"tile-description-" + index}
                variant="body2"
                color={disabled ? "text.disabled" : "text.primary"}
              >
                {description}
              </Typography>
            </Grid>

            <Grid item xs={childrenSize}>
              {children}
            </Grid>
          </Grid>
        </CardContent>
      </ButtonBase>
    </Card>
  );
};
