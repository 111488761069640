import React from "react";

import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import FormWrapper from "./FormWrapper";

export const FormCheckbox = props => {
  return (
    <FormWrapper
      props={props}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      checkbox
      render={({ field, fieldState: { error } }) => (
        <FormControl required={props.required} error={!!error}>
          <FormControlLabel
            label={
              typeof props.description === "string"
                ? (props.required ? " * " : "") + props.description
                : props.description
            }
            key={props.id}
            control={
              <Checkbox
                id={"formCheckbox-" + (props.id ?? props.name)}
                name={props.name}
                disabled={props.disabled}
                color="primary"
                {...field}
                checked={field.value}
                onChange={e => {
                  field.onChange(e.target.checked);
                  props?.onChanged?.(e.target.checked);
                }}
                inputRef={field.ref}
              />
            }
          />
          {error && <FormHelperText>{error ? error.message : " "}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default FormCheckbox;
