import React from "react";

import { IconButton, FormControl, FormHelperText, InputLabel, MenuItem, Select, Box } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { TooltipWrapper } from "../TooltipWrapper/TooltipWrapper";
import FormWrapper from "./FormWrapper";

export const FormSelect = props => {
  const oneLineDisplay = props.oneLineDisplay ?? false;

  return (
    <FormWrapper
      props={props}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      style={props.style}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TooltipWrapper text={props.helperText}>
          <FormControl fullWidth variant={props.variant} required={props.required} error={!!error}>
            <InputLabel id={props.label}>{props.label}</InputLabel>
            <Box display="flex" alignItems="center">
              <Select
                name={props.name}
                id={props.id ?? props.name}
                label={props.label}
                disabled={props.disabled}
                value={value}
                style={{ ...props.style, flexGrow: 1 }}
                inputRef={ref}
                onChange={onChange}
                sx={{ "&& .MuiSelect-select": oneLineDisplay ? {} : { whiteSpace: "break-spaces" } }}
              >
                {props.items === undefined
                  ? null
                  : props.items.map(item => (
                    <MenuItem
                      id={"formSelect-item-" + (props.id ?? props.name) + "-" + item.id}
                      key={item.id}
                      value={item.id}
                      style={props.style}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>

              <IconButton 
                onClick={() => onChange(null)}
                edge="end"
                size="small"
                style={{ visibility: props.disabled ? 'hidden' : 'visible' }}
              >
                <ClearIcon />
              </IconButton>
            </Box>
            <FormHelperText>{error?.message ? error?.message : " "}</FormHelperText>
          </FormControl>
        </TooltipWrapper>
      )}
    />
  );
};

export default FormSelect;
