import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Alert, AlertTitle, Grid, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as React from "react";
import myStyles from "./style";
const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500
  }
});

export const TooltipWrapper = ({ text, children, alert = false }) => {
  const classes = myStyles();
  return text ? (
    <>
      {alert ? (
        <Grid item xs={12} sm={12} mb={2}>
          <Alert severity="warning" className={classes.noPrint}>
            <AlertTitle>{"Uwaga"}</AlertTitle>
            {text}
          </Alert>
        </Grid>
      ) : (
        <CustomWidthTooltip
          title={text}
          enterDelay={500}
          leaveDelay={200}
          arrow
          placement="top"
          className={classes.noPrint}
        >
          <IconButton size="small" sx={{ position: "absolute", right: 0, zIndex: 10 }}>
            <HelpOutlineIcon sx={{ height: 15, width: 15 }} />
          </IconButton>
        </CustomWidthTooltip>
      )}
      {children}
    </>
  ) : (
    children
  );
};
