import { makeStyles } from "@mui/styles";

const drawerWidth = 240;
const background = "#f4f5f7";
export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // marginTop: theme.spacing(3),
    minHeight: `calc(100vh - ${theme.spacing(3)})`,
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    backgroundColor: background,
  },
  root2: {
    display: "flex",
    marginBottom: 60,
    "@media print": {
      display: "none",
      width: 0,
    },
  },
  rootShift: {
    display: "flex",
    flexDirection: "column",
    // marginTop: theme.spacing(3),
    minHeight: `calc(100vh - ${theme.spacing(3)})`,
    marginLeft: drawerWidth,
    // marginRight: theme.spacing(1),
    backgroundColor: background,
    "@media print": {
      marginLeft: 0,
    },
  },
  rootLogin: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: background,
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
    "@media print": {
      display: "none",
      width: "0vw",
    },
  },
  main: {
    //marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: "1px",
    padding: "18px 20px 20px 20px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //marginLeft: -drawerWidth+30,
  },
  footer: {
    padding: "10px 20px",
    marginTop: "auto",
    textAlign: "center",
    backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[500],
    "& p": {
      fontSize: 12,
      color: "#8d8d8d",
    },
  },
}));
