import { enqueueSnackbar } from "notistack";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const isNotEmpty = (item) => item !== undefined && item !== null && item !== "";

export function toStartOfDay(date) {
  if (date == null) { return null; }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function dateTimeWithSec(date) {
  return date ? `${moment(date).format("DD.MM.YYYY HH:mm:ss")}` : "";
}

export function getLookupName(lookups, value) {
  return lookups.find((x) => x.id === value)?.name || null;
}

/**
 * funkcja do filtrowania danych w obiekcie wg klucza
 * @param {*} keyName klucz obiektu
 * @param {*} dataSet dane obiektu
 * @returns obiekt
 */
export function FilterObjectByKey(keyName, dataSet) {
  let data = [];

  Object.entries(dataSet).forEach(([key, value]) => {
    if (key.startsWith(keyName) && key) {
      if (value === true) {
        data.push(key.replace(/\D/g, ""));
      }
    }
  });
  return data;
}

/**
 * Funkcja do formatowania daty
 * @param {*} date data
 * @returns sformatowana data
 */
export const formatDateTime = (date) =>
  new Intl.DateTimeFormat("pl-PL", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  }).format(date);

export const formatMonth = (date) =>
  new Intl.DateTimeFormat("pl-PL", {
    month: "long",
  }).format(date);

export const formatTextField = (text) => (text && text.trim().length > 0 ? text.trim() : undefined);

export function getAppVersion() {
  return getMeta("app-version");
}

export function getEnvironmentName() {
  return getMeta("environment-name");
}

export function getApiKey() {
  return getMeta("api-key");
}

export function getEncryptionKey() {
  return getMeta("encryption-key");
}

export function getMeta(metaName) {
  const metas = document.getElementsByTagName("meta");

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i].getAttribute("content");
    }
  }

  return "";
}

/**
 * Funkcja do przekierowania po kliknięciu na przycisk (useNavigate)
 * @param {*} url url strony z locales.url
 * @param {*} params obiekt z parametrami do przekazania na następną stronę
 * @returns function
 */
export function GoToUrl(url, params = null) {
  const onSubmit = () => {
    isExternalUrl(url) ? window.open(url, "_blank") : navigate(url, { params });
  };

  let navigate = useNavigate();
  return onSubmit;
}

export function isExternalUrl(url) {
  var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
  if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== location.protocol) return true;
  if (
    typeof match[2] === "string" &&
    match[2].length > 0 &&
    match[2].replace(new RegExp(":(" + { "http:": 80, "https:": 443 }[location.protocol] + ")?$"), "") !== location.host
  )
    return true;
  return false;
}

/**
 * Funkcja do cofanie się o określoną liczbę stron do tyłu
 * @param {*} prev ile stron do tyłu
 * @returns function
 */
export function historyBack(prev = -1) {
  return () => window.history.back(prev);
}

export const onInvalidForm = () => {
  return enqueueSnackbar("Formularz zawiera błędy. Popraw je.", {
    variant: "error",
    persist: true,
  });
};

export function HoursToMiliseconds(hours) {
  let ms = hours * (60000 * 60);
  return ms;
}

export function formatPrize(prize) {
  if (prize.includes(".")) return prize.replace(".", ",");

  if (!prize.includes(",")) return `${prize},00`;

  return prize;
}
