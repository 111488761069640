import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  menuItems: {
    "& > a": {
      padding: "8px 0 6px 12px",
    },
    "& span": {
      fontSize: "14px",
      color: "#5c5a59",
      lineHeight: "1",
    },
    "& li": {
      color: "#222222",
      fontWeight: "bold",
      fontSize: "14px",
      padding: "20px 0 10px 0",
      lineHeight: "1",
    },
    "& svg": {
      color: "#5c5a59",
      width: 28,
      height: 28,
    },
    "@media print": {
      display: "none",
    },
  },
  menuDropdownItem: {
    fontSize: "14px",
    color: "#5c5a59",
    lineHeight: "1",
    "@media print": {
      display: "none",
    },
  },
  menuListItemIcon: {
    minWidth: "auto",
    marginRight: 20,
    "@media print": {
      display: "none",
    },
  },
}));

export default useStyles;
