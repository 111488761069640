import { patchData, postData } from "@fenix/core/services/api";

export const authorize = async () => await postData("/security/authorize");

export const unauthorize = async () => await postData("/security/unauthorize");

export const resetPasswordInit = async email => await patchData("/security/password/reset-init", { email });

export const resetPasswordConfirm = async (password, userToken, operationToken) =>
  await patchData("/security/password/reset-confirm", {
    password,
    userToken,
    operationToken
  });

export const changePassword = async (oldPassword, newPassword) =>
  await patchData("/security/password/change", { oldPassword, newPassword });
