import { Box } from "@mui/material";
import Loader from "../Loader/Loader";

export const LoaderWrapper = ({ children, isLoading }) => {
  return (
    <>
      {!!isLoading ? (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader />
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default LoaderWrapper;
