import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import useStyles from "./style";

export const FormPopup = ({
  popup,
  displayAsPopup,
  form,
  onSubmit,
  onError,
  onCancel,
  children,
  isReadOnly = false,
  width,
  buttonLabel = "Zapisz",
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleCancel = async () => {
    if (onCancel) await onCancel();

    popup.handleClose(false);
  };

  const handleSubmit = async (data) => {
    if (disableSubmit) return;

    if (onSubmit) {
      try {
        setDisableSubmit(true);

        const result = await onSubmit(data);

        if (result?.message) enqueueSnackbar(result?.message, { variant: "success" });

        if (!result?.error) popup.handleClose(true);
      } catch (ex) {
        throw ex;
      } finally {
        setDisableSubmit(false);
      }
    }
  };

  return displayAsPopup ? (
    <Dialog fullWidth={true} maxWidth={"md"} open={true} onClose={handleCancel} className={classes.popup}>
      <DialogTitle>
        <div>
          <Typography className={classes.title}>{popup.title}</Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>{popup.description}</DialogContentText>         */}
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {!isReadOnly && (
          <Button
            color="primary"
            variant="contained"
            onClick={form.handleSubmit(handleSubmit, onError)}
            className={classes.submitButton}
            id="formPopup-save-btn"
            disabled={disableSubmit}
          >
            {buttonLabel}
          </Button>
        )}
        <Button variant="outlined" onClick={handleCancel} className={classes.cancelButton} id="formPopup-close-btn">
          {isReadOnly ? "Zamknij" : "Anuluj"}
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Paper sx={{ borderRadius: 4, padding: 5 }}>
      <Container component="main" className={classes.paper} maxWidth={width ?? "md"} disableGutters>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography style={{ textAlign: "center" }} component="h2" variant="h6">
              {popup.title}
            </Typography>
          </Grid>
          <Grid item style={{ maxWidth: "100%", overflowX: "scroll" }}>{children}</Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} direction="row">
              <Grid item xs={10} sm={10}>
                {!isReadOnly && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={form.handleSubmit(handleSubmit, onError)}
                    className={classes.submitButton}
                    disabled={disableSubmit}
                    id="formPopup-save-btn"
                  >
                    {buttonLabel}
                  </Button>
                )}
              </Grid>
              <Grid item xs={2} sm={2}>
                <Button variant="outlined" onClick={handleCancel} className={classes.cancelButton} id="formPopup-close-btn">
                  {isReadOnly ? "Zamknij" : "Anuluj"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};
