import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import FormWrapper from "./FormWrapper";

/**
 *
 * @param props.type typ pola input (valid HTML5 input type).
 * @param props.control dla walidacji
 * @param props.name - nazwa pola
 * @param props.label - opis pola
 * @param props.required - czy wymagane
 * @param props.variant - typ graficzny dostępne opcje: filled | outlined | standard
 * @param props.xs - szerokość pola w grid przy mobile
 * @param props.sm - szerokość pola w grid przy desktop
 * @param props.rules - warunki walidacji
 *
 * @returns pole tekstowe typu input
 */

export const FormPasswordField = props => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <FormWrapper
      props={props}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TextField
          autoComplete={props.autocomplete}
          inputRef={ref}
          key={props.name}
          name={props.name}
          id={props.id ?? props.name}
          label={props.label}
          required={props.required}
          variant={props.variant ? props.variant : "outlined"}
          fullWidth
          value={value}
          onChange={onChange}
          type={showPassword ? "text" : "password"}
          error={!!error}
          helperText={error ? error.message : " "}
          InputProps={{
            maxLength: props.maxLength,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  id={`formPasswordField-showPassword-${props.name}-btn`}
                  aria-label="pokaż/ukryj hasło"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};

export default FormPasswordField;
