import React from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useSnackbar } from "notistack";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, Container, Grid, Typography } from "@mui/material";

import { FormTextField } from "@fenix/core/controls/FormTypes";
import { onInvalidForm } from "@fenix/core/helpers/tools";
import Meta from "@fenix/core/components/Meta/Meta";
import { resetPasswordInit } from "./security.api";
import { emailPattern } from "@fenix/core/helpers/patterns";

import useStyles from "./style";

const PasswordResetInit = props => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const form = useForm({ shouldFocusError: true });
  const { handleSubmit } = form;
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async data => {
    setLoading(true);
    const result = await resetPasswordInit(data.email);
    if (result.error === undefined) {
      enqueueSnackbar("Jeśli istnieje konto powiązane z wpisanym adresem email otrzymasz link do resetowania hasła.", {
        variant: "success"
      });
      navigate("/");
    }
    setLoading(false);
  };

  return (
    <Container className={classes.paper} component="main" maxWidth="md">
      <Meta subTitle={"Przypomnij hasło"}></Meta>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography className={classes.title} component="h1" variant="h5">
        {"Przypomnienie hasła"}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit, (t) => onInvalidForm(t))} noValidate>
        <Grid container direction="column" spacing={2} width={450}>
          <FormTextField
            form={form}
            label="Email"
            required={true}
            name={"email"}
            key={"email"}
            trim={true}
            rules={{
              required: "Adres e-mail jest wymagany",
              pattern: { value: emailPattern, message: "Niepoprawny format adresu e-mail" } }}
          />
          <LoadingButton
            loading={loading}
            // loadingPosition="start"
            id="passwordResetInit-submit-btn"
            type="submit"
            //fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            width={300}
          >
            {"Przypomnij"}
          </LoadingButton>
        </Grid>
      </form>
    </Container>
  );
};

export default PasswordResetInit;
