import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useStyles from "./style";

const ItemWithDropdown = ({ item }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = `/${item.link}` === location.pathname;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = item => {
    navigate(`/${item.link}`, { refresh: isActive });
    setAnchorEl(null);
  };

  return (
    <div>
      <ListItemButton className="menu-list-item" onClick={handleClick}>
        <ListItemIcon className={classes.menuListItemIcon}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        <ChevronRightIcon />
      </ListItemButton>
      <Menu
        id="menuItems-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        open={open}
        onClose={handleClose}
      >
        {item?.items?.map(listItem => {
          return (
            <MenuItem
              key={"mi-" + listItem.link}
              id={"menuItems-item-" + listItem.link}
              sx={{ px: 4, justifyContent: "center" }}
              className={classes.menuDropdownItem}
              onClick={() => handleClose(listItem)}
            >
              {listItem.title}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export const DrawerItem = ({ item }) => {
  const classes = useStyles();
  const location = useLocation();
  const isActive = `/${item.link}` === location.pathname;

  const key = item.link;

  return (
    <ListItemButton
      component={Link}
      to={item.link}
      key={key}
      id={`menuItems-drawerItem-${key}`}
      sx={{
        fontWeight: isActive ? "bold" : null
      }}
    >
      <ListItemIcon className={classes.menuListItemIcon}>{item.icon}</ListItemIcon>
      <ListItemText
        primary={item.title}
        primaryTypographyProps={{ style: { whiteSpace: "pre-line", fontWeight: isActive ? "bold" : null } }}
      />
    </ListItemButton>
  );
};

export function useMenuItems(navigation) {
  const classes = useStyles();

  const menuItems = [];
  navigation.forEach((navigationGroup, groupIndex) => {
    menuItems.push(<ListSubheader key={navigationGroup.title}>{navigationGroup.title}</ListSubheader>);

    navigationGroup.items.forEach((item, itemIndex) => {
      if (item.items) {
        menuItems.push(<ItemWithDropdown key={`${groupIndex}-${itemIndex}`} item={item} />);
      } else {
        menuItems.push(<DrawerItem key={`${groupIndex}-${itemIndex}`} item={item} />);
      }
    });
  });

  return <div className={classes.menuItems}>{menuItems}</div>;
}
