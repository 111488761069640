import React, { createContext, useEffect, useState } from "react";
import { setAxiosClientAuthHeaders } from "@fenix/core/services/axios";
import { authorize } from "./security.api";

const UserContext = createContext();
UserContext.displayName = "UserContext";

export const UserContextProvider = ({ children, withSession = false, type = "localStorage" }) => {
  const storage = type === "sessionStorage" ? sessionStorage : localStorage;

  const [token, setToken] = useState(null);

  const startAuthorization = async (jwtToken) => {
    setToken(jwtToken);
    setAxiosClientAuthHeaders(jwtToken);
    let data = await authorize();

    if (!data.error) {
      data.token = jwtToken;
      saveUserContext(data);
    }
  };

  const getUserContext = () => {
    if (withSession) {
      if (window.location.pathname === "/security/reset-init" || window.location.pathname === "/security/reset-proceed") {
        return undefined;
      }

      const context = storage.getItem("context");
      if (context != null && context !== "undefined") {
        const parsedContext = JSON.parse(context);
        setAxiosClientAuthHeaders(parsedContext.token);
        return parsedContext;
      } else {
        return undefined;
      }
    }
  };

  const [userContext, setUserContext] = useState(getUserContext());

  const saveUserContext = (userContext) => {
    withSession && storage.setItem("context", JSON.stringify(userContext));
    setUserContext(userContext);

    // setAxiosClientAuthHeaders(token);
  };

  const clearUserContext = () => {
    
    storage.removeItem("context");
    //setToken(null);
    setUserContext(undefined);

    setAxiosClientAuthHeaders(null);
  };

  useEffect(() => {
    if (userContext !== undefined && userContext?.token === undefined) {
      let data = userContext;
      userContext.token = token;
      saveUserContext(data);
    }
    setAxiosClientAuthHeaders(userContext?.token);
  }, [userContext]);

  return (
    <UserContext.Provider
      value={{
        setUserContext: saveUserContext,
        userContext,
        clearUserContext,
        startAuthorization,
        token,
        isAuthenticated: !!userContext
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => React.useContext(UserContext);

export default useUserContext;
