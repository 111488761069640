import React, { useMemo } from "react";
import moment from "moment";

import { Button, Grid, IconButton, Stack, Typography, Link } from "@mui/material";
import { ArrowCircleDown as ArrowCircleDownIcon, ArrowCircleUp as ArrowCircleUpIcon } from "@mui/icons-material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridPagination,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";

import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useUserContext } from "@fenix/core/context";
import { getLookupName } from "../../helpers/tools";

function ExpandableCell({ value, textLength = 100 }) {
  const [expanded, setExpanded] = React.useState(false);
  const length = textLength;

  return (
    value && (
      <div style={{ overflow: "hidden" }}>
        {expanded ? value : value.slice(0, length)}&nbsp;
        {value.length > length && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link type="button" component="button" sx={{ fontSize: "inherit" }} onClick={() => setExpanded(!expanded)}>
            {expanded ? "mniej" : "więcej"}
          </Link>
        )}
      </div>
    )
  );
}

export const numberColumn = {
  type: "number",
  filterOperators: getGridNumericOperators().filter(
    (operator) =>
      operator.value === "=" ||
      operator.value === "!=" ||
      operator.value === ">" ||
      operator.value === ">=" ||
      operator.value === "<" ||
      operator.value === "<="
  ),
};

export const currencyColumn = {
  type: "number",
  valueFormatter: ({ value }) =>
    new Intl.NumberFormat("pl-PL", {
      style: "currency",
      currency: "PLN",
    }).format(Number(value)),
  filterOperators: getGridNumericOperators().filter(
    (operator) =>
      operator.value === "=" ||
      operator.value === "!=" ||
      operator.value === ">" ||
      operator.value === ">=" ||
      operator.value === "<" ||
      operator.value === "<="
  ),
};

export const booleanColumn = {
  type: "boolean",
};

export const dateColumn = {
  type: "date",
  valueFormatter: (params) => (params.value ? `${moment(params.value).format("DD.MM.YYYY")}` : ""),
  filterOperators: getGridDateOperators(true).filter((operator) => operator.value === "onOrAfter" || operator.value === "before"),
};

export const dateTimeColumn = {
  type: "dateTime",
  valueFormatter: (params) => (params.value ? `${moment(params.value).format("DD.MM.YYYY HH:mm")}` : ""),
  filterOperators: getGridDateOperators(true).filter((operator) => operator.value === "onOrAfter" || operator.value === "before"),
};

export const dateTimeWithSecColumn = {
  type: "dateTimeWithSec",
  valueFormatter: (params) => (params.value ? `${moment(params.value).format("DD.MM.YYYY HH:mm:ss")}` : ""),
  filterOperators: getGridDateOperators(true).filter((operator) => operator.value === "onOrAfter" || operator.value === "before"),
};

export const singleSelectColumn = {
  type: "singleSelect",
  filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === "is" || operator.value === "not"),
};

export const lookupColumn = function (lookups) {
  return { 
    type: "singleSelect",
    sortable: false,
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === "is" || operator.value === "not"),
    valueGetter: (rowData) => getLookupName(lookups, rowData.value),
    filterValues: lookups.map(x => ({ id: x.id, value: x.name }))
  }
}

export const stringColumn = {
  type: "string",
  filterOperators: getGridStringOperators().filter(
    (operator) =>
      operator.value === "equals" ||
      operator.value === "contains" ||
      operator.value === "isEmpty" ||
      operator.value === "isNotEmpty" ||
      operator.value === "startsWith" ||
      operator.value === "endsWith"
  ),
};

export const expandableStringColumn = (textLength) => {
  return {
    ...stringColumn,
    renderCell: (params) => <ExpandableCell {...params} textLength={textLength} />,
  };
};

export const actionsColumn = {
  type: "actions",
  align: "left",
  width: 150,
};

const HeaderComponent = (props) => {
  return (
    <Grid container direction="row" padding={"0 0 20px 0"}>
      <Grid item xs={6} marginTop="10px" marginBottom="10px">
        <Stack spacing={1} direction="row" justifyContent="flex-start">
          <Typography component="h3">{props.title}</Typography>
          <Typography component="h3" style={{ marginLeft: "14px" }}>
            {props.subTitle}
          </Typography>
        </Stack>
      </Grid>
      {/* <Grid item xs={6} container justifyContent="flex-end">
        {props.gridActions}
      </Grid> */}
    </Grid>
  );
};

const FooterComponent = (props) => {
  return (
    <Grid container direction="row">
      <Grid item xs={6}>
        <Stack spacing={1} direction="row" justifyContent="flex-start" style={{ margin: "14px 30px 14px 30px" }}>
          {props.gridActions}
        </Stack>
      </Grid>
      <Grid item xs={6} container justifyContent="flex-end" style={{ padding: "14px 30px 14px 0" }}>
        <GridPagination className="DataGridPagination" />
      </Grid>
    </Grid>
  );
};

export const DataView = ({
  title,
  columns,
  actions,
  loadData,
  style,
  hideFooter,
  initialSort = [],
  initialFilter = { items: [] },
  withOrderColumn,
  changeOrder,
  dataRefresh,
}) => {
  const [refresh, setRefresh] = React.useState(null);
  const [filter, setFilter] = React.useState(initialFilter);
  const [order, setOrder] = React.useState(initialSort);
  // const [page, setPage] = React.useState(0);
  // const [pageSize, setPageSize] = React.useState(10);
  const [data, setData] = React.useState({ rowCount: 0, rows: [], loading: false });
  const [popup, setPopup] = React.useState(null);
  const [subTitle, setSubTitle] = React.useState(null);
  const location = useLocation();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  //const columnFilterMappings = x => columns.filter(c => c.valueOptions !== undefined).map(c => {c.field, c.valueOptions});

  React.useEffect(() => {
    //setRefresh(location.key);
    setSubTitle(null);
    setPopup(null);
  }, [location.key]);

  const getFilterValue = (field, value) => {
    const options = columns.find((c) => c.filterValues && c.field === field)?.filterValues;
    return options && options.length > 0 ? options.find((o) => o.value === value).id : value;
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      setData({ ...data, loading: true });

      const filters = filter?.items
        .filter((x) => x.value || x.operator === "isEmpty" || x.operator === "isNotEmpty")
        .map((x) => `${x.field}^${x.operator}^${getFilterValue(x.field, x.value)}`)
        .join("|");
      const sort = order.map((x) => `${x?.field}^${x.sort}`).join("|");
      const limit = `${paginationModel.page * paginationModel.pageSize}^${paginationModel.pageSize}`;
      const response = await loadData(filters, sort, limit);

      if (!active) {
        return;
      }

      if (active && response !== undefined) {
        setData({
          rowCount: response.limit?.total ? response.limit.total : response.length ?? 0,
          rows: response.result ? response.result : response,
          loading: false,
        });
      }
    })();

    return () => (active = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRefresh, refresh, filter, order, paginationModel, location.key]);

  const onAction = React.useCallback(
    (a, e) => async () => {
      if (a.onClick) {
        let content = a.onClick({
          action: a,
          item: e?.row,
          popup: {
            title: a.title,
            description: a.description,
            handleClose: (result) => {
              setSubTitle(null);
              setPopup(null);
              setRefresh({});
              //setTimeout(() => setRefresh({}), 300);
            },
          },
          data,
        });

        if (content) {
          setSubTitle(">  " + a.title);
          setPopup(content);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const { userContext } = useUserContext();
  const user = userContext?.user;

  const rowActions = actions
    ?.filter((a) => a.type === "row" && user.permissions.includes(a.permission))
    ?.map((a, i) => ({
      ...actionsColumn,
      ...a,
      field: `field_${i}`,
      headerName: a.label,
      getActions: (e) => [
        !a.actionVisible || (a.actionVisible && a.actionVisible(e?.row)) ? (
          a.isTextButton ? (
            <Button sx={{ borderRadius: 15 }} variant="contained" {...a} onClick={onAction(a, e)}>
              {a.buttonText}
            </Button>
          ) : (
            <GridActionsCellItem {...a} onClick={onAction(a, e)} />
          )
        ) : (
          <></>
        ),
      ],
    }));

  const multipleRowActions = actions
    ?.filter((a) => a.type === "rowMulti")
    ?.map((a, i) => ({
      ...a,
      ...actionsColumn,
      field: `multifield_${i}`,
      headerName: a.label,
      getActions: (e) =>
        a.actions
          ?.filter((rowAction) => user.permissions.includes(rowAction.permission))
          ?.map((rowAction, index) => {
            const { readonlyTitle, actionVisible, ...newRowAction } = rowAction;
            return !actionVisible || rowAction.actionVisible(e?.row) ? (
              <GridActionsCellItem {...newRowAction} onClick={onAction(rowAction, e)} />
            ) : (
              <></>
            );
          }),
    }));

  const gridActions = actions
    ?.filter((a) => a.type === "grid" && user.permissions.includes(a.permission))
    .map((a, i) => (
      <Button
        {...a}
        variant={i > 0 ? "outlined" : "contained"}
        color={i > 0 ? "primary" : "primary"}
        onClick={onAction(a, null)}
        key={a.label}
        id={a.id}
        startIcon={a.icon}
        className="GridActionButton"
        style={{ fontSize: "20px" }}
      >
        {a.label}
      </Button>
    ));

  const orderColumn = useMemo(
    () => ({
      field: "order",
      headerName: "Kolejność",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Stack direction="row">
          <IconButton
            size="small"
            color="primary"
            onClick={async () => {
              await changeOrder(params.row.id, true);
              setRefresh({});
            }}
          >
            <ArrowCircleUpIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="primary"
            onClick={async () => {
              await changeOrder(params.row.id, false);
              setRefresh({});
            }}
          >
            <ArrowCircleDownIcon fontSize="small" />
          </IconButton>
        </Stack>
      ),
    }),
    [changeOrder]
  );

  if (withOrderColumn && !columns.includes(orderColumn)) {
    columns.unshift(orderColumn);
  }

  const gridColumns = columns
    .map((c) => ({
      valueOptions: c.filterValues ? c.filterValues.map((x) => x.value) : undefined,
      type: "singleSelect",
      ...c,
    }))
    .concat(rowActions)
    .concat(multipleRowActions);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    columns
      .filter((c) => c.hide)
      .map((c) => c.field)
      .reduce((result, item) => {
        result[item] = false;
        return result;
      }, {})
  );

  const backColor = useTheme().palette.common.black;

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
      className="DataGrid"
      id={data.loading ? "dataView-loading" : "dataView-finished"}
    >
      <HeaderComponent title={title} subTitle={subTitle} />
      {popup ? (
        popup
      ) : (
        <DataGridPro
          initialState={{
            sorting: {
              sortModel: initialSort,
            },
            filter: {
              filterModel: initialFilter,
            },
          }}
          sx={{
            flexGrow: 1,
            p: 3,
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "clip",
              whiteSpace: "break-spaces",
              lineHeight: 1,
              fontWeight: "bold",
            },
            "& .MuiDataGrid-renderingZone": {
              maxHeight: "none !important",
            },
            "& .MuiDataGrid-cell": {
              lineHeight: "unset !important",
              maxHeight: "none !important",
              whiteSpace: "normal",
            },
            "& .MuiDataGrid-row": {
              maxHeight: "none !important",
            },
          }}
          slots={{ footer: FooterComponent }}
          hideFooter={hideFooter}
          slotProps={{ footer: { gridActions } }}
          columns={gridColumns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          disableColumnPinning
          rowAlter
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 30, 50]}
          sortingMode="server"
          sortModel={order}
          onSortModelChange={(s) => setOrder(s)}
          filterMode="server"
          filterModel={filter}
          onFilterModelChange={(f) => setFilter(f)}
          rowHeight={50}
          headerHeight={60}
          getEstimatedRowHeight={() => 100}
          getRowHeight={() => "auto"}
          {...data}
          style={{
            backgroundColor: "#ffffff",
            borderRadius: 15,
            padding: 0,
            color: { backColor },
            fontSize: 14,
            ...style,
          }}
        />
      )}
    </div>
  );
};
