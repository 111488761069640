import { QuestionPopup } from "@fenix/core/components/Popup/QuestionPopup";

export const ConfirmDeleteDialog = (props) => {
    const onSubmit = async () => {
        await props.onConfirm(props.data);
    };

    return (
        <QuestionPopup popup={props.popup} onSubmit={onSubmit}>
            {props.text}
        </QuestionPopup>
    );
};