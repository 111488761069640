import { useSnackbar } from "notistack";
import { useAPIError } from "./APIErrorProvider";

export default function APIErrorNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { error, removeError } = useAPIError();

  const handleSubmit = () => {
    removeError();
    closeSnackbar();
  };

  return <div style={{display: "none"}}>{error
    ? enqueueSnackbar(error.message, {
        variant: "error",
        onClose: () => handleSubmit(),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        preventDuplicate: true,
        persist: true,
      })
    : null}
    </div>;
}
