import axios from "axios";
import queryString from "query-string";
import { getMeta } from "../helpers/tools";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import { SignJWT, jwtVerify } from "jose";

var encryptionKey = "";

export const createAxiosClient = function (baseUrl) {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json"
    },
    paramsSerializer: {
      serialize: params => queryString.stringify(params)
    }
  });
};

export const createAxiosClientNamed = function (clientName) {
  return createAxiosClient(getMeta(`api-url-${clientName}`));
};

export const axiosClient = createAxiosClient(getMeta("api-url"));

export const setAxiosEncryptionKey = newEncryptionKey => {
  encryptionKey = newEncryptionKey;
};

export const setAxiosClientAuthHeaders = async authToken => {
  authToken?.length > 0
    ? (axiosClient.defaults.headers.common["Authorization"] = "Bearer " + authToken)
    : delete axiosClient.defaults.headers.common["Authorization"];
};

export const setAxiosClientProviderHeaders = async providerId => {
  delete axiosClient.defaults.headers.common["X-Provider-ID"];
  if (providerId) axiosClient.defaults.headers.common["X-Provider-ID"] = providerId;
};

export const setAxiosClientLanguageHeaders = async lang => {
  if (lang) axiosClient.defaults.headers.common["Content-Language"] = lang;
};

export const setAxiosClientHeader = function (header, value) {
  if (value) {
    axiosClient.defaults.headers.common[header] = value;
  } else {
    delete axiosClient.defaults.headers.common[header];
  }
};

export const setAxiosClientInterceptor = (addError, unauthorize, navigate, useEncryption) => {
  axiosClient.interceptors.request.clear();
  axiosClient.interceptors.response.clear();

  axiosClient.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      let responseData = error.response?.data;

      if (responseData instanceof Blob) {
        const text = await responseData.text();
        responseData = JSON.parse(text);
      }

      const message =
        (error.response?.data?.code ?? 0) +
        ": " +
        (error.response?.data?.message || error.response?.statusText || error.message);
      const status = error.response?.status ?? 0;

      console.log("request error:" + message);
      addError(message, status);
      if (status === 401 || status === 403) {
        if (navigate) {
          let url = window.location.pathname === '/login' ? 
            (window.location.pathname + window.location.search) : ("/login?back_url=" + window.location.pathname);

            navigate(url);
          }
        // unauthorize();
      }

      return Promise.reject(error);
    }
  );

  if (!useEncryption) {
    return;
  }

  axiosClient.interceptors.request.use(async config => {
    if (config.data) {
      let serializedData = JSON.stringify(config.data);
      const hashDigest = Base64.stringify(sha256(serializedData));
      const secret = new TextEncoder().encode(encryptionKey);
      const alg = "HS256";

      let result = await new SignJWT({ sig: hashDigest }).setProtectedHeader({ alg }).sign(secret);

      config.data = serializedData;
      config.headers["x-signature"] = result;
    }

    return config;
  });

  axiosClient.interceptors.response.use(async function (response) {
    let signatureHeader = response.headers.get("x-signature");
    if (response.data && signatureHeader) {
      const responseText = response.data.toString();
      const hashDigest = Base64.stringify(sha256(responseText));
      const secret = new TextEncoder().encode(encryptionKey);

      const verifyResult = await jwtVerify(signatureHeader, secret);
      const payload = verifyResult.payload;
      if (payload.sig != hashDigest) {
        addError("Niepoprawny podpis odpowiedzi", 0);
      }
    }

    return response;
  });
};
