import React from "react";

import { Drawer, List } from "@mui/material";

import useStyles from "./style";
import { useMenuItems } from "./components/menuItems";

const Menu = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.isDrawerOpen);
  const navigation = props.navigation;

  const mainListItems = useMenuItems(navigation);

  React.useEffect(() => {
    setOpen(props.isDrawerOpen);
  }, [props.isDrawerOpen]);

  return (
    <Drawer
      className={classes.drawer}
      id="menu-drawer"
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.listItems}>
        <List>{mainListItems}</List>
      </div>
    </Drawer>
  );
};

export default Menu;
