import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import * as React from "react";
import { NumericFormat } from "react-number-format";
import { TooltipWrapper } from "../TooltipWrapper/TooltipWrapper";
import FormWrapper from "./FormWrapper";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalSeparator=","
      thousandSeparator=" "
      valueIsNumericString
      fixedDecimalScale
      allowNegative={false}
      decimalScale={2}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export const FormDecimalField = props => {
  return (
    <FormWrapper
      props={props}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TooltipWrapper text={props.helperText}>
          <TextField
            inputRef={ref}
            name={props.name}
            id={props.name}
            label={props.label}
            placeholder={props.placeholder}
            required={props.required}
            variant={props.variant ?? "outlined"}
            fullWidth
            value={value}
            onChange={onChange}
            disabled={props.disabled}
            error={!!error}
            helperText={error ? error.message : " "}
            multiline={props.multiline}
            minRows={props.minRows}
            inputProps={{
              maxLength: props.maxLength
            }}
            InputProps={{
              inputComponent: NumberFormatCustom
            }}
          />
        </TooltipWrapper>
      )}
    />
  );
};

export default FormDecimalField;
