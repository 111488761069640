import { Box, CircularProgress } from "@mui/material";

// loading component for suspense fallback
const Loader = () => {
  return (
    <Box display="flex" justifyContent="center" m={1} p={1}>
      <Box p={2}>
        <CircularProgress
          size={48}
          sx={{
            //color: green[500],
            top: -6,
            left: -6,
            zIndex: 1
          }}
        />
      </Box>
    </Box>
  );
};

export default Loader;
