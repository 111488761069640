import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { TooltipWrapper } from "../TooltipWrapper/TooltipWrapper";
import FormWrapper from "./FormWrapper";

export const FormAutocomplete = props => {
  return (
    <FormWrapper
      props={props}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TooltipWrapper text={props.helperText}>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => onChange(newValue)}
            multiple={props.multiple ?? false}
            id={props.name}
            options={props.options}
            getOptionLabel={option => props.options.find(x => x.id === option.id)?.name ?? ''}
            disabled={props.disabled}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                label={props.label}
                fullWidth
                helperText={error ? error.message : " "}
                required={props.required}
                error={!!error}
                inputRef={ref}
              />
            )}
          />
        </TooltipWrapper>
      )}
    />
  );
};

export default FormAutocomplete;
