import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Container, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useForm } from "react-hook-form";
import useStyles from "./style";
import { useUserContext } from "@fenix/core/context";
import { FormTextField, FormPasswordField, FormPasswordStrenghtBar } from "@fenix/core/controls/FormTypes";
import { changePassword } from "./security.api";
import { useNavigate } from "react-router-dom";
import { onInvalidForm } from "@fenix/core/helpers/tools";


export default function PasswordChange(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm({ shouldFocusError: true });
  const { handleSubmit, getValues } = form;
  const { userContext, clearUserContext } = useUserContext();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const result = await changePassword(data.oldPassword, data.newPassword);
      if (!result.error) {
        enqueueSnackbar("Hasło zostało zmienione. Zaloguj się ponownie.", { variant: "success" });
        clearUserContext();
        navigate('/login');
      }
    } catch (ex) {
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className={classes.paper} component="main" maxWidth="md">
      <Typography className={classes.title} component="h1" variant="h5">
        Zmiana hasła
      </Typography>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit, (t) => onInvalidForm(t))} noValidate>
        <Grid container spacing={4}>
          <FormTextField
            variant="outlined"
            name="email"
            label="Twój adres email"
            required={true}
            disabled={true}
            form={form}
            value={userContext?.user.login}
            //rules={{
            //  required: "Podaj email",
            //  pattern: { value: emailPattern, message: "Podaj poprawny email" },
            //}}
          />
          
          <FormPasswordField
            autocomplete="new-password"
            variant="outlined"
            name="oldPassword"
            key="oldPassword"
            label="Podaj stare hasło"
            required={true}
            form={form}
            rules={{
              required: "Podaj hasło",
            }}
          />

          <FormPasswordField
            autocomplete="new-password"
            variant="outlined"
            name="newPassword"
            key="newPassword"
            label="Podaj nowe hasło"
            required={true}
            form={form}
            rules={{
              required: "Podaj hasło",
            }}
          />

          <FormPasswordField
            autocomplete="new-password"
            variant="outlined"
            name="newPassword2"
            key="newPassword2"
            label="Powtórz nowe hasło"
            required={true}
            form={form}
            rules={{
              required: "Podaj powtórnie hasło",
              validate: (value) => value === getValues("newPassword") || "Hasła muszą być takie same",
            }}
          />

          <FormPasswordStrenghtBar name="passwordStrenght" form={form} passwordFieldName={"newPassword"}/>

          <LoadingButton
            loading={loading}
            // loadingPosition="start"
            sx={{ marginLeft: "16px", marginTop: "16px" }}
            id="passwordChange-submit-btn"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {"Zmień"}
          </LoadingButton>
        </Grid>
      </form>
      <br />
      <Alert severity="info" variant="outlined">
        {"Po zmianie hasła przekierujemy Cię na formatkę logowania"}
      </Alert>
    </Container>
  );
}
