import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export const QuestionPopup = ({ popup, onSubmit, onCancel, children }) => {

  const buttonCancelStyle = {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
    textTransform: "none",
    minWidth: { xs: 80, md: 140 },
    minHeight: { xs: 40, md: 60 },
    borderRadius: "8px"
  };

  const submitButtonStyle = {
    fontSize: 18,
    fontWeight: "bold",
    textTransform: "none",
    minWidth: { xs: 80, md: 140 },
    minHeight: { xs: 40, md: 60 },
    borderRadius: "8px"
  };

  const handleClose = async result => {
    if (result && onSubmit) await onSubmit();
    else if (!result && onCancel) await onCancel();

    popup.handleClose(result);
  };

  return (
    <Dialog open={true} onClose={() => handleClose(false)}>
      <DialogTitle>
        <div>
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: "bold",
              textTransform: "uppercase"
            }}
          >
            {popup.title}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{
          fontSize: 16
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: { xs: "center", sm: "flex-start" },
          padding: "24px"
        }}
      >
        <Button
          color="primary"
          sx={submitButtonStyle}
          variant="contained"
          id="questionPopup-yes-btn"
          onClick={() => handleClose(true)}
        >
          Tak
        </Button>
        <Button variant="outlined" id="questionPopup-no-btn" onClick={() => handleClose(false)} sx={buttonCancelStyle}>
          Nie
        </Button>
      </DialogActions>
    </Dialog>
  );
};
