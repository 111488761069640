import { makeStyles } from "@mui/styles";

const myStyles = makeStyles((theme) => ({
   noPrint: {
        '@media print' : {
            display: "none"
        }
    }

}));

export default myStyles;