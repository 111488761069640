import { getData, patchData, postData, putData } from "@fenix/core/services/api";

export const getUsers = async (filter, sort, limit) => await getData("/security/users", { filter, sort, limit });

// export const getUsersLookup = async (role, quickSearch) =>
//   await getData("/security/users/lookup", { role, quickSearch });

export const getUserPermissionGroups = async (user = null) => {
  var userId = user ? user.id : 0;
  return await getData(`/security/users/${userId}/permission-groups`);
};

export const getUserProfiles = async (user = null) => {
  var userId = user ? user.id : 0;
  return await getData(`/security/users/${userId}/profiles`);
};

// export const getUser = async (id) => await getData(`/users/${id}/backend`);

export const saveUser = async (data) => await postData(`/security/users`, data);

export const updateUser = async (data) => await putData(`/security/users/${data.id}`, data);

export const deleteUser = async (id) => await patchData(`/security/users/${id}`);

export const activateUserAccount = async (data) => await patchData(`/security/users/activate-account`, data);

export const sendActivateAccountEmail = async (userId) => await postData('/security/send-activate-account-email', userId);

export const userStatusLookups = async (userId) => await getData('/security/user-status-lookups');