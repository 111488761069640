import Stack from "@mui/material/Stack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Typography } from "@mui/material";

export default function ErrorInfo(message) {
    return (
        <Stack direction="row" alignItems="center" gap={1} justifyContent="center">
          <ErrorOutlineIcon color="error"></ErrorOutlineIcon>
          <Typography variant="h5" color="error">
            {message}
          </Typography>
        </Stack>
      );
}