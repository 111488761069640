import PasswordStrengthBar from "react-password-strength-bar";
import FormWrapper from "./FormWrapper";

export const FormPasswordStrenghtBar = props => {
  const password = props.form.watch(props.passwordFieldName);

  return (
    <FormWrapper
      props={props}
      render={({ field: {}, fieldState: {} }) => (
        <PasswordStrengthBar
          minLength={12}
          shortScoreWord={"Hasło jest zbyt krótkie"}
          scoreWords={[
            "Siła hasła: Bardzo słabe",
            "Siła hasła: Słabe",
            "Siła hasła: Średnie",
            "Siła hasła: Silne",
            "Siła hasła: Bardzo silne"
          ]}
          style={{ width: "100%" }}
          password={password}
        />
      )}
    />
  );
};
